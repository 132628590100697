import { Config } from '../../interfaces/config.interface';
import Logo from './logo-segunda-seguros.png';

const config: Config = {
  name: 'La Segunda Seguros',
  title: 'Voluntariado',
  logo: Logo,
  logo_width: 85,
};

export default config;
